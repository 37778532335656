<tandem-loading-container [isLoaded]="themeLoaded">
  <div class="flex min-h-full flex-col justify-center py-12 px-5 sm:px-6 lg:px-8 relative">
    <a class="absolute top-4 left-4" href="https://tandemfinance.app">
      <button
        class="bg-primary text-white font-medium py-2 pl-3 pr-4 rounded-lg flex items-center gap-2 hover:bg-primary-dark transition duration-200"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
        </svg>
        Return Home
      </button>
    </a>
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <!--    <img [src]="'assets/svg/logo/full_blue.svg'" class="mx-auto h-40 w-auto">-->
      <img [src]="logoPath" class="mx-auto h-40 w-auto">
      <h2 class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in to your account</h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
      <div class="bg-white px-6 py-12 shadow rounded-lg sm:px-12">
        <form class="space-y-6" action="#" method="POST">
          <tandem-form-input [form]="loginForm" controlName="email" label="Email Address" placeholder="Email Address" inputType="email"></tandem-form-input>
          <tandem-form-input [form]="loginForm" (keydown.enter)="keydownSignInWithEmail.bind(this)" controlName="password" label="Password" placeholder="Password" inputType="password"></tandem-form-input>

          <div class="flex items-center justify-start">
            <div class="text-sm leading-6">
              <a (click)="forgotPassword()" class="font-semibold text-primary hover:text-primary cursor-pointer">Forgot password?</a>
            </div>
          </div>

          <div>
            <tandem-button class="w-full mt-2"  [onClick]="signInWithEmail.bind(this)" [disabled]="loginForm.invalid" [type]="'submit'" color="primary">Sign In</tandem-button>
          </div>
        </form>

        <div>
          <div class="relative mt-10">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-200"></div>
            </div>
            <div class="relative flex justify-center text-sm font-medium leading-6">
              <span class="bg-white px-6 text-gray-900">Or continue with</span>
            </div>
          </div>

          <div class="mt-6 flex justify-center gap-4">
            <a (click)="onGoogleLogin()" class="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:cursor-pointer focus-visible:ring-transparent">
              <svg class="h-5 w-5" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z" fill="#EA4335" />
                <path d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z" fill="#4285F4" />
                <path d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z" fill="#FBBC05" />
                <path d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z" fill="#34A853" />
              </svg>
              <span class="text-sm font-semibold leading-6">Google</span>
            </a>
          </div>
        </div>
      </div>

      <p class="mt-10 text-center text-sm text-gray-500">
        Not a member?
        <a (click)="goToRegister()" class="font-semibold leading-6 text-primary hover:text-primary hover:cursor-pointer">Sign Up</a>
      </p>
    </div>
  </div>
</tandem-loading-container>
