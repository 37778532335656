import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {HeaderService} from "../../../tandem-core/services/header.service";
import {TandemUser} from "../../models/tandem-user";
import {DialogService, TandemDialogConfig} from "../../../tandem-core/services/dialog.service";
import {ThemingService} from "../../../tandem-core/services/theming.service";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {LinkedInstitution} from "../../../plaid/models/linked-institution";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {environment} from "../../../../../environments/environment";
import {UserService} from "../../user.service";
import {UserSubscription} from "../../models/user-subscription";
import {PaymentService} from "../../../stripe/services/payment-service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {VideoPlayerService, VideoType, VideoUrl} from "../../../tandem-core/services/video-player.service";
import {Timestamp} from "firebase/firestore";
import {VideoPlayerComponent} from "../../../tandem-core/components/video-player/video-player.component";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {finalize} from "rxjs";
import {Router} from "@angular/router";
import { Location } from '@angular/common';


@Component({
  selector: 'tandem-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  user: TandemUser | null = null;
  coach: TandemUser | null = null;

  themes: string[] = [];

  feedbackForm: FormGroup = new FormGroup<any>({});

  private SERVER_PATH = environment.functionsPath;
  public userSubscription: UserSubscription | undefined;

  sanitizedVideoURL: SafeResourceUrl | undefined;
  editingVideoURL: number = -1;
  videoForm: FormGroup = new FormGroup<any>({});

  colorTooltip = 'Enter either an RGB color in the format “RGB(XX,XX,XX)” or as a HEX code in the format “#XXXXXX” and press “Save” to apply';
  fontTooltip = 'Enter the exact name of any Google Font and hit “Save” to apply';
  logoTooltip = 'Upload your logo as an SVG. This file type ensures your logo is not distorted or blurred. If you have your logo as another file type (png, jpg, pdf), you can easily convert the file to SVG. See our walkthrough video if you need guidance';
  iconTooltip = 'Upload your icon as an SVG. This file type ensures your logo is not distorted or blurred. If you have your logo as another file type (png, jpg, pdf), you can easily convert the file to SVG. See our walkthrough video if you need guidance';

  videoCallLinkTooltip = `An optional link that you can specify that allows your users to set up video calls with you (Zoom, Calendly, etc.)`;

  videoConfigs: {title: string; tooltip: string; videoUrl: string; videoType: VideoType}[] = [
    {
      title: 'User Welcome Video',
      tooltip: 'Upload a video that welcomes your users to the platform and explains the basics',
      videoType: 'userWelcomeIndividual',
      videoUrl: environment.defaultVideoConfig.userWelcomeIndividual
    },
    {
      title: 'Financial Position',
      tooltip: `Upload a video that walks your users through how to use the Financial Position Tracking Tool`,
      videoUrl: environment.defaultVideoConfig.financialPosition,
      videoType: "financialPosition"
    },
    {
      title: 'Cash Flow',
      tooltip: `Upload a video that walks your users through how to use the Cash Flow Tracking Tool`,
      videoUrl: environment.defaultVideoConfig.cashFlow,
      videoType: "cashFlow"
    },
    {
      title: 'Prioritized Spending Plan',
      tooltip: `Upload a video that walks your users through how to use the Prioritized Spending Plan Tracking Tool`,
      videoUrl: environment.defaultVideoConfig.spendingPlan,
      videoType: "spendingPlan"
    },
    {
      title: 'Debt Payoff Calculator',
      tooltip: `Upload a video that walks your users through how to use the Debt Payoff Calculator`,
      videoUrl: environment.defaultVideoConfig.debtPayoff,
      videoType: "debtPayoff"
    },
    {
      title: 'Investment Calculator',
      tooltip: `Upload a video that walks your users through how to use the Investment Calculator`,
      videoUrl: environment.defaultVideoConfig.investment,
      videoType: "investment"
    },
    {
      title: 'Debt Vs Invest',
      tooltip: `Upload a video that walks your users through how to use the Debt vs. Invest Calculator`,
      videoUrl: environment.defaultVideoConfig.debtVsInvest,
      videoType: "debtVsInvest"
    },
  ];

  foundVideoConfigs: VideoUrl[] = [];
  editingName = false;
  editingCoachBusinessName = false;
  editingColor = false;
  editingHeaderFont = false;
  editingBodyFont = false;
  editingPhoneNumber = false;
  editingVideoCallLink = false;
  editingContact = false;
  displayNameForm: FormGroup = this.fb.group({name: [null, Validators.required]})
  businessNameForm: FormGroup = this.fb.group({name: [null, Validators.required]})
  colorForm: FormGroup = this.fb.group({color: [null, [Validators.required, colorValidator()]]})
  headerFontForm: FormGroup = this.fb.group({font: [null, Validators.required]})
  bodyFontForm: FormGroup = this.fb.group({font: [null, Validators.required]})


  phoneNumberForm: FormGroup = this.fb.group({phoneNumber: [null, Validators.required]})
  videoCallLinkForm: FormGroup = this.fb.group({videoCallLink: [null, [Validators.required, urlValidator()]]})

  editingPassword = false;
  passwordForm: FormGroup = new FormGroup<any>({});

  visibilityForm: FormGroup = new FormGroup<any>({})

  isUpdatingFromUser = false;



  constructor(private auth: AuthService,
              private afAuth: AngularFireAuth,
              private sanitizer: DomSanitizer,
              private fb: FormBuilder,
              private headerService: HeaderService,
              private dialogService: DialogService,
              private themingService: ThemingService,
              private paymentService: PaymentService,
              private userService: UserService,
              private location: Location,
              private http: HttpClient,
              private storage: AngularFireStorage,
              private videoService: VideoPlayerService) {

    this.passwordForm = this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, { validator: this.passwordMatchValidator });

    this.visibilityForm = this.fb.group({
      fp: false,
      cf: false,
      sp: false,
    })
  }

  ngOnInit(): void {

    this.headerService.setConfig(undefined);
    this.feedbackForm = this.fb.group({
      subject: [null, Validators.required],
      feedback: [null, Validators.required]
    })
    this.videoForm = this.fb.group({
      url: [null, Validators.required]
    });
    // this.headerService.setConfig({title: 'Profile'});
    this.auth.$user.subscribe(user => {
      this.user = user;
      if (this.user) {
        this.displayNameForm.get('name')?.setValue(user?.displayName);
        // set video names
        this.videoConfigs[1].title = user?.customTTMetadata?.fpNamePlural || 'Financial Positions';
        this.videoConfigs[2].title = user?.customTTMetadata?.cashFlowNamePlural || 'Cash Flows';
        this.videoConfigs[3].title = user?.customTTMetadata?.spendingPlanNamePlural || 'Spending Plans';

        this.isUpdatingFromUser = true;
        if (this.visibilityForm.get('fp')?.value !== this.user.visibleFinancialPositions) {
          this.visibilityForm.get('fp')?.setValue(this.user.visibleFinancialPositions);
        }

        if (this.visibilityForm.get('cf')?.value !== this.user.visibleCashFlows) {
          this.visibilityForm.get('cf')?.setValue(this.user.visibleCashFlows);
        }

        if (this.visibilityForm.get('sp')?.value !== this.user.visibleSpendingPlans) {
          this.visibilityForm.get('sp')?.setValue(this.user.visibleSpendingPlans);
        }
        this.isUpdatingFromUser = false;

        if (this.user.coachId) {
          this.auth.getUser(this.user.coachId).subscribe((coach: TandemUser) => {
            this.coach = coach;
          })
        }

        if (this.user.accountType === 'coach' && !!this.user.coachType) {
          this.videoConfigs[0] = {
            videoUrl: this.user.coachType === 'coachRevShare' ? environment.defaultVideoConfig.userWelcomeRev : environment.defaultVideoConfig.userWelcomeLead,
            title: `User Welcome Video - ${this.user.coachType === 'coachRevShare' ? 'Rev Share' : 'Lead Gen'}`,
            videoType: this.user.coachType === 'coachRevShare' ? 'userWelcomeRev' : 'userWelcomeLead',
            tooltip: this.videoConfigs[0].tooltip
          };
        }
        // this.userService.getSubscriptionInformationForUser(this.user.uid).subscribe(userSub => {
        //   console.log(user)
        //   this.userSubscription = userSub;
        // })
        if (this.user && !this.user.coachId) {
          this.videoService.listByCoachId(this.user.uid).subscribe(videos => {
            this.foundVideoConfigs = videos;
            videos.forEach(video => {
              switch (video.type) {
                case "userWelcomeRev":
                  this.videoConfigs[0].videoUrl = video.url;
                  break;
                case "userWelcomeLead":
                  this.videoConfigs[0].videoUrl = video.url;
                  break;
                case "financialPosition":
                  this.videoConfigs[1].videoUrl = video.url;
                  this.videoConfigs[1].title = user?.customTTMetadata?.fpNamePlural || 'Financial Positions';
                  break;
                case "cashFlow":
                  this.videoConfigs[2].videoUrl = video.url;
                  this.videoConfigs[2].title = user?.customTTMetadata?.cashFlowNamePlural || 'Cash Flows';
                  break;
                case "spendingPlan":
                  this.videoConfigs[3].videoUrl = video.url;
                  this.videoConfigs[3].title = user?.customTTMetadata?.spendingPlanNamePlural || 'Spending Plans';
                  break;
                case "debtPayoff":
                  this.videoConfigs[4].videoUrl = video.url;
                  break;
                case "investment":
                  this.videoConfigs[5].videoUrl = video.url;
                  break;
                case "debtVsInvest":
                  this.videoConfigs[6].videoUrl = video.url;
                  break;
              }
            })
          })
        }
        this.videoCallLinkForm.get('videoCallLink')?.setValue(this.user?.videoCallLink);
      }
    });
    // @ts-ignore
    this.themes = Object.keys(this.themingService.themes).map((k: string) => this.themingService.themes[k]).map((theme: {primary: string, secondary: string}) => theme.primary);
    this.visibilityForm.valueChanges.subscribe(visibility => {
      if (!this.isUpdatingFromUser && this.user) {
        const updatedUser = { ...this.user };
        updatedUser.visibleFinancialPositions = this.visibilityForm.get('fp')?.value || false;
        updatedUser.visibleCashFlows = this.visibilityForm.get('cf')?.value || false;
        updatedUser.visibleSpendingPlans = this.visibilityForm.get('sp')?.value || false;

        if (
          updatedUser.visibleFinancialPositions !== this.user.visibleFinancialPositions ||
          updatedUser.visibleCashFlows !== this.user.visibleCashFlows ||
          updatedUser.visibleSpendingPlans !== this.user.visibleSpendingPlans
        ) {
          this.userService.update(updatedUser).then(res => {
            this.location.replaceState('profile');
            setTimeout(() => {
              // Any additional actions after update
            }, 100);
          });
        }
      }
    });
  }

  openStripe() {
    this.paymentService.openStripe();
  }

  changeEmail() {
    this.auth.changeEmail()
  }

  resetPassword() {
    this.auth.changePassword()
  }

  onLogout(): void {
    this.auth.onLogout();
  }

  getSanitizedUrl(videoUrl: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
  }

  toggleVideoEdit(index: number) {
    if (this.editingVideoURL === index) {
      this.editingVideoURL = -1;
    } else {
      this.videoForm.get('url')?.setValue(this.videoConfigs[index].videoUrl);
      this.editingVideoURL = index;
    }
  }

  updateURL(index: number) {
    let type: VideoType = 'userWelcomeIndividual';
    switch (index) {
      case 0:
        type = this.user?.coachType === 'coachRevShare' ? 'userWelcomeRev' : 'userWelcomeLead';
        break;
      case 1:
        type = 'financialPosition';
        break;
      case 2:
        type = 'cashFlow';
        break;
      case 3:
        type = 'spendingPlan';
        break;
      case 4:
        type = 'debtPayoff';
        break;
      case 5:
        type = 'investment';
        break;
      case 6:
        type = 'debtVsInvest';
        break;
    }

    let vid: VideoUrl | undefined = this.foundVideoConfigs.find(config => config.type === type);

    if (vid) {
      vid.url = this.getYouTubeEmbedUrl(this.videoForm.get('url')?.value);

      this.videoService.update(vid).then(res => {
        this.videoForm.reset();
        this.editingVideoURL = -1;
      });
    } else {
      this.videoService.add({
        url: this.getYouTubeEmbedUrl(this.videoForm.get('url')?.value),
        type: type,
        coachId: this.user!.id!,
        dateModified: Timestamp.now(),
        dateCreated: Timestamp.now(),
      }).then(res => {
        this.videoForm.reset();
        this.editingVideoURL = -1;
      });
    }
  }

  private getYouTubeEmbedUrl(url: string | undefined): string {
    if (!url) {
      return '';
    }

    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      return `https://www.youtube.com/embed/${match[2]}`;
    }

    return url; // Return the original URL if it's not a valid YouTube link
  }

  sendSupportEmail() {
    const config: TandemDialogConfig = {
      type: 'loading',
      title: 'Contacting Support',
      content: 'Please wait while we send your message',
    }
    this.dialogService.openModal2(config);
    this.http.post(`${environment.functionsPath}/sendContactEmail`, {email: this.user?.email, subject: this.feedbackForm.get('subject')?.value, message: this.feedbackForm.get('feedback')?.value}).subscribe(response => {
      this.feedbackForm.reset();
      this.dialogService.closeModal2();
      const success: TandemDialogConfig = {
        type: 'success',
        title: 'Contacted Support',
        content: 'Your email has been sent to support. We should be back with you soon.',
        actions: [
          {
            text: 'Okay',
            role: 'close',
            callback: () => this.dialogService.closeModal2()
          }
        ]
      }
      this.dialogService.openModal2(success);
      this.editingContact = false;
      this.feedbackForm.reset();
    });
  }

  switchTheme(t: string) {
    this.dialogService.openConfirmDialog('Change Theme', 'Are you sure you want to change your theme? ' +
      'This will update the appearance of the Tandem application for you as well as all of your users.')
      .afterClosed()
      .subscribe(confirmed => {
        this.updateUserTheme(this.themingService.themeNames[this.themes.indexOf(t)]).then(res => {
        })
      })
  }

  public async updateUserTheme(theme: string) {
    const idToken = await this.getIdToken();
    if (idToken) {
      const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
      const params = new HttpParams().set('newTheme', theme);
      return this.http.get<LinkedInstitution[]>(`${this.SERVER_PATH}/updateUserTheme`,{ headers, params }).toPromise();
    }
    // Handle error or throw an error if no user or ID token
    throw new Error('User is not currently signed in.');
  }

  private async getIdToken() {
    const user = await this.afAuth.currentUser;
    if (user) {
      return user.getIdToken();
    }
    return null;
  }

  openVideo(videoUrl: string) {
    this.dialogService.openModal(VideoPlayerComponent, {videoUrl: videoUrl});
  }

  closeNameEdit(saveChanges: boolean) {
    this.editingName = false;
    if (saveChanges && this.user) {
      this.user.displayName = this.displayNameForm.get('name')?.value;
      const names = this.user.displayName.trim().split(" ");
      this.user.firstName = names[0];
      if (names.length > 1) {
        this.user.lastName = names[names.length - 1];
      } else {
        this.user.lastName = '';
      }
      this.userService.update(this.user).then(res => {
      })
    } else {
      this.displayNameForm.get('name')?.setValue(this.user?.displayName);
    }
  }

  closeBusinessNameEdit(saveChanges: boolean) {
    this.editingCoachBusinessName = false;
    if (saveChanges && this.user) {
      this.user.coachBusinessName = this.businessNameForm.get('name')?.value;
      this.userService.update(this.user).then(res => {
      })
    } else {
      this.businessNameForm.get('name')?.setValue(this.user?.coachBusinessName);
    }
  }

  closePhoneNumber(saveChanges: boolean) {
    this.editingPhoneNumber = false;
    if (saveChanges && this.user) {
      this.user.phoneNumber = this.phoneNumberForm.get('phoneNumber')?.value;
      this.userService.update(this.user).then(res => {
      })
    } else {
      this.phoneNumberForm.get('phoneNumber')?.setValue(this.user?.phoneNumber);
    }
  }

  closeVideoCallLink(saveChanges: boolean) {
    this.editingVideoCallLink = false;
    if (saveChanges && this.user) {
      this.user.videoCallLink = this.videoCallLinkForm.get('videoCallLink')?.value;
      this.userService.update(this.user).then(res => {
      })
    } else {
      this.videoCallLinkForm.get('videoCallLink')?.setValue(this.user?.videoCallLink);
    }
  }

  uploadPhoto(event: any) {
    const loadingConfig: TandemDialogConfig = {
      type: 'loading',
      title: 'Updating Profile Picture',
      content: 'Please wait while we process your image',
    };
    this.dialogService.openModal2(loadingConfig);

    const file = event.target.files[0];
    if (!file) {
      this.dialogService.closeModal2();
      return;
    }

    const filePath = `profile_pictures/${this.user!.uid}/${file.name}`;
    const fileRef = this.storage.ref(filePath);

    const task = this.storage.upload(filePath, file);

    task.percentageChanges().subscribe(
      percentage => {
        console.log(percentage);
      },
      error => {
        this.handleUploadError(error);
      }
    );

    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(
          url => {
            this.user!.photoURL = url;
            this.userService.update(this.user!).then(
              () => {
                this.dialogService.closeModal2();
                setTimeout(() => {
                }, 100)
              },
              error => {
                this.handleUploadError(error);
              }
            );
          },
          error => {
            this.handleUploadError(error);
          }
        );
      })
    ).subscribe(
      null,
      error => {
        this.handleUploadError(error);
      }
    );
  }

  private handleUploadError(error: any) {
    console.error('Upload failed:', error);
    this.dialogService.closeModal2();

    const errorConfig: TandemDialogConfig = {
      type: 'failure',
      title: 'Upload Failed',
      content: 'There was an error uploading your profile picture. Please try again later.',
      actions: [
        {
          text: 'Close',
          role: 'close',
          callback: () => this.dialogService.closeModal2()
        }
      ]
    };
    this.dialogService.openModal2(errorConfig);
  }

  closeFeedback(sendMessage: boolean) {
    if (sendMessage) {
      this.sendSupportEmail();
    } else {
      this.feedbackForm.reset();
      this.editingContact = false;
    }
  }

  promptEditContact() {
    this.editingContact = true;
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('newPassword')?.value === g.get('confirmPassword')?.value
      ? null : { mismatch: true };
  }

  cancelPasswordEdit() {
    this.editingPassword = false;
    this.passwordForm.reset();
  }

  updatePassword() {
    if (this.passwordForm.valid) {
      const { currentPassword, newPassword } = this.passwordForm.value;

      this.auth.updatePassword(currentPassword, newPassword).then(
        () => {
          this.dialogService.openModal2({
            type: 'success',
            title: 'Password Updated',
            content: 'Your password has been successfully updated.',
            actions: [
              {
                text: 'OK',
                role: 'close',
                callback: () => this.dialogService.closeModal2()
              }
            ]
          });
          this.editingPassword = false;
          this.passwordForm.reset();
        },
        (error) => {
          console.log(error)
          this.dialogService.openModal2({
            type: 'failure',
            title: 'Password Update Failed',
            content: error.message.includes('auth/invalid-login-credentials') ? `Invalid current password. This also might be happening if you originally signed up with Google.` : error.message,
            actions: [
              {
                text: 'OK',
                role: 'close',
                callback: () => this.dialogService.closeModal2()
              }
            ]
          });
        }
      );
    } else {
      console.log(this.passwordForm)
      if (this.passwordForm.hasError('mismatch')) {
        this.dialogService.openModal2({
          type: 'failure',
          title: 'Passwords Don\'t Match',
          content: 'Your passwords must match. Please try again.',
          actions: [
            {
              text: 'Close',
              role: 'close',
              callback: () => this.dialogService.closeModal2()
            }
          ]
        })
      }
    }
  }

  getLabel(tt: string) {
    return this.visibilityForm.get(tt)?.value ? 'Coach can view' : 'Coach can\'t view';
  }

  closeColorEdit(saveChanges: boolean) {
    this.editingColor = false;

    if (saveChanges && this.user) {
      this.dialogService.openModal2({
        type: 'confirm',
        title: 'Confirm Update',
        content: `Are you sure you want to perform this update? The theming change will immediately take effect for you and all of your users.`,
        actions: [
          {
            text: 'No, Cancel',
            callback: () => {
              this.dialogService.closeModal2;
              this.colorForm.reset();
            },
            role: 'cancel'
          },
          {
            role: 'confirm',
            text: 'Yes, Change Theme',
            callback: () => {
              this.updateThemeForAllUsersAndCoach(`${this.colorForm.get('color')?.value}`).then(res => {
                this.dialogService.openModal2({
                  type: 'success',
                  title: 'Updated Theme',
                  content: `Successfully updated the Tandem theme for you and all of your associated users.`,
                  actions: [
                    {
                      text: 'Close',
                      role: 'close',
                      callback: this.dialogService.closeModal2
                    }
                  ]
                })
              })
            }
          }
        ]
      })
    }
  }

  closeFontEdit(saveChanges: boolean, fontType: 'header' | 'body') {
    if (fontType === 'header') {
      this.editingHeaderFont = false;
    } else {
      this.editingBodyFont = false;
    }

    if (saveChanges && this.user) {
      this.dialogService.openModal2({
        type: 'confirm',
        title: 'Confirm Update',
        content: `Are you sure you want to perform this update? The theming change will immediately take effect for you and all of your users.`,
        actions: [
          {
            text: 'No, Cancel',
            callback: () => {
              this.dialogService.closeModal2;
              this.headerFontForm.reset()
              this.bodyFontForm.reset()
            },
            role: 'cancel'
          },
          {
            role: 'confirm',
            text: 'Yes, Change Font',
            callback: () => {
              if (fontType === 'header') {
                this.updateHeaderFont(`${this.headerFontForm.get('font')?.value}`).then(res => {

                })
              } else {
                this.updateBodyFont(`${this.bodyFontForm.get('font')?.value}`).then(res => {

                })
              }

            }
          }
        ]
      })
    }
  }

  openStripeBillingInfo() {
    this.paymentService.openStripeBillingInfo();
  }

  private async updateThemeForAllUsersAndCoach(newColorHex: string): Promise<any> {
    this.dialogService.openModal2({
      type: 'loading',
      title: 'Performing Update',
      content: 'Please wait while we process your changes...'
    });
    try {
      const idToken = await this.auth.getIdToken();

      if (!idToken) {
        throw new Error("No ID token available");
      }

      // const ref = this.dialogService.openLoadingDialog('Finishing Up', `We've processed your payment, now we just need to wrap a few things up.`);
      const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);

      // Use await to wait for the HTTP response
      const result = await this.http.post<any>(`${environment.functionsPath}/changeTheme`, {
        newColorHex: newColorHex,
      }, { headers }).toPromise();

      if (result.error) {
        this.dialogService.closeModal2();
        // ref.close();
        throw new Error(result.error.message);
      }

      return result;
    } catch (error) {
      // Handle errors that occur during the process
      this.dialogService.closeModal2();
      console.error(error);
      throw error;
    }
  }

  private async updateHeaderFont(headerFont: string): Promise<any> {
    this.dialogService.openModal2({
      type: 'loading',
      title: 'Performing Update',
      content: 'Please wait while we process your changes...'
    });

    try {
      const isValid = await this.isGoogleFont(headerFont); // await the Google font validation

      console.log(isValid ? `${headerFont} is a valid Google Font` : `${headerFont} is not a valid Google Font`);

      if (!isValid) {
        // Open a failure dialog if the font is not valid
        this.dialogService.openModal2({
          type: 'failure',
          title: 'Unrecognized Font',
          content: `It doesn't look like ${headerFont} is a valid Google Font. For a list of valid fonts you can go to https://fonts.google.com/`,
          actions: [{ text: 'Close', role: 'close', callback: () => this.dialogService.closeModal2() }]
        });
        return; // Exit early if the font is invalid
      }

      // Continue if the font is valid
      const idToken = await this.auth.getIdToken();

      if (!idToken) {
        throw new Error("No ID token available");
      }

      // Set up headers
      const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);

      // Await the HTTP request to change the font
      const result = await this.http.post<any>(`${environment.functionsPath}/changeFont`, {
        font: headerFont,
        fontType: 'header'
      }, { headers }).toPromise();

      // Check for errors in the result
      if (result.error) {
        throw new Error(result.error.message);
      }

      this.dialogService.closeModal2();
      this.dialogService.openModal2({
        type: 'success',
        title: 'Updated Theme',
        content: `Successfully updated the font for you and all of your associated users.`,
        actions: [
          {
            text: 'Close',
            role: 'close',
            callback: this.dialogService.closeModal2
          }
        ]
      })
      return result;

    } catch (error) {
      // Handle any errors that occur in the process
      this.dialogService.closeModal2();
      console.error(error);
      throw error; // Re-throw to propagate error handling to the calling function if necessary
    }
  }

  private async changeBrandingImage(body: {logoURL?: string; iconURL?: string; resetLogo?: boolean; resetIcon?: boolean}): Promise<any> {
    this.dialogService.openModal2({
      type: 'loading',
      title: 'Performing Update',
      content: 'Please wait while we process your changes...'
    });
    try {
      const idToken = await this.auth.getIdToken();

      if (!idToken) {
        throw new Error("No ID token available");
      }

      // const ref = this.dialogService.openLoadingDialog('Finishing Up', `We've processed your payment, now we just need to wrap a few things up.`);
      const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);

      // Use await to wait for the HTTP response
      const result = await this.http.post<any>(`${environment.functionsPath}/changeBrandingImage`, body, { headers }).toPromise();

      if (result.error) {
        this.dialogService.closeModal2();
        // ref.close();
        throw new Error(result.error.message);
      }

      return result;
    } catch (error) {
      // Handle errors that occur during the process
      this.dialogService.closeModal2();
      console.error(error);
      throw error;
    }
  }

  private async updateBodyFont(bodyFont: string): Promise<any> {
    this.dialogService.openModal2({
      type: 'loading',
      title: 'Performing Update',
      content: 'Please wait while we process your changes...'
    });

    try {
      const isValid = await this.isGoogleFont(bodyFont); // await the Google font validation

      console.log(isValid ? `${bodyFont} is a valid Google Font` : `${bodyFont} is not a valid Google Font`);

      if (!isValid) {
        // Open a failure dialog if the font is not valid
        this.dialogService.openModal2({
          type: 'failure',
          title: 'Unrecognized Font',
          content: `It doesn't look like ${bodyFont} is a valid Google Font. For a list of valid fonts you can go to https://fonts.google.com/`,
          actions: [{ text: 'Close', role: 'close', callback: () => this.dialogService.closeModal2() }]
        });
        return; // Exit early if the font is invalid
      }

      // Continue if the font is valid
      const idToken = await this.auth.getIdToken();

      if (!idToken) {
        throw new Error("No ID token available");
      }

      // Set up headers
      const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);

      // Await the HTTP request to change the font
      const result = await this.http.post<any>(`${environment.functionsPath}/changeFont`, {
        font: bodyFont,
        fontType: 'body'
      }, { headers }).toPromise();

      // Check for errors in the result
      if (result.error) {
        throw new Error(result.error.message);
      }

      this.dialogService.closeModal2();
      this.dialogService.openModal2({
        type: 'success',
        title: 'Updated Theme',
        content: `Successfully updated the font for you and all of your associated users.`,
        actions: [
          {
            text: 'Close',
            role: 'close',
            callback: this.dialogService.closeModal2
          }
        ]
      })
      return result;

    } catch (error) {
      // Handle any errors that occur in the process
      this.dialogService.closeModal2();
      console.error(error);
      throw error; // Re-throw to propagate error handling to the calling function if necessary
    }
  }

  uploadLogo(event: any) {
    const loadingConfig: TandemDialogConfig = {
      type: 'loading',
      title: 'Updating Logo',
      content: 'Please wait while we process your image',
    };
    this.dialogService.openModal2(loadingConfig);

    const file = event.target.files[0];
    if (!file) {
      this.dialogService.closeModal2();
      return;
    }

    const filePath = `logos/${this.user!.uid}/${file.name}`;
    const fileRef = this.storage.ref(filePath);

    const task = this.storage.upload(filePath, file);

    task.percentageChanges().subscribe(
      percentage => {
        console.log(percentage);
      },
      error => {
        this.handleUploadError(error);
      }
    );

    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(
          url => {

          this.changeBrandingImage({logoURL: url}).then(res => {
            this.dialogService.openModal2({
              type: 'success',
              title: 'Updated Logo',
              content: `Successfully updated the logo for you and all of your associated users.`,
              actions: [
                {
                  text: 'Close',
                  role: 'close',
                  callback: this.dialogService.closeModal2
                }
              ]
            })
          });
          },
          error => {
            this.handleUploadError(error);
          }
        );
      })
    ).subscribe(
      null,
      error => {
        this.handleUploadError(error);
      }
    );
  }

  uploadIcon(event: any) {
    const loadingConfig: TandemDialogConfig = {
      type: 'loading',
      title: 'Updating Icon',
      content: 'Please wait while we process your image',
    };
    this.dialogService.openModal2(loadingConfig);

    const file = event.target.files[0];
    if (!file) {
      this.dialogService.closeModal2();
      return;
    }

    const filePath = `icons/${this.user!.uid}/${file.name}`;
    const fileRef = this.storage.ref(filePath);

    const task = this.storage.upload(filePath, file);

    task.percentageChanges().subscribe(
      percentage => {
        console.log(percentage);
      },
      error => {
        this.handleUploadError(error);
      }
    );

    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(
          url => {

            this.changeBrandingImage({iconURL: url}).then(res => {
              this.dialogService.openModal2({
                type: 'success',
                title: 'Updated Icon',
                content: `Successfully updated the icon for you and all of your associated users.`,
                actions: [
                  {
                    text: 'Close',
                    role: 'close',
                    callback: this.dialogService.closeModal2
                  }
                ]
              })
            });
          },
          error => {
            this.handleUploadError(error);
          }
        );
      })
    ).subscribe(
      null,
      error => {
        this.handleUploadError(error);
      }
    );
  }

  protected readonly environment = environment;

  resetColor() {
    this.dialogService.openModal2({
      type: 'confirm',
      title: 'Reset Color',
      content: `Are you sure you want to reset your primary color to the Tandem default? This change will immediately be applied for you and all of your users.`,
      actions: [
        {
          text: 'No, Cancel',
          callback: () => this.dialogService.closeModal2,
          role: 'cancel'
        },
        {
          text: 'Yes, Confirm',
          callback: () => {
            this.updateThemeForAllUsersAndCoach(environment.defaultTheme).then(res => {
              this.dialogService.openModal2({
                type: 'success',
                title: 'Updated Theme',
                content: `Successfully updated the primary color for you and all of your associated users.`,
                actions: [
                  {
                    text: 'Close',
                    role: 'close',
                    callback: this.dialogService.closeModal2
                  }
                ]
              })
            })
          },
          role: 'confirm'
        },
      ]
    })
  }

  resetFont(fontType: 'header' | 'body') {
    this.dialogService.openModal2({
      type: 'confirm',
      title: 'Reset Font',
      content: `Are you sure you want to reset your ${fontType} font to the Tandem default? This change will immediately be applied for you and all of your users.`,
      actions: [
        {
          text: 'No, Cancel',
          callback: () => this.dialogService.closeModal2,
          role: 'cancel'
        },
        {
          text: 'Yes, Confirm',
          callback: () => {

            if (fontType === 'header') {
              this.updateHeaderFont(environment.defaultHeaderFont).then(res => {
                this.dialogService.openModal2({
                  type: 'success',
                  title: 'Updated Header Font',
                  content: `Successfully updated the font for you and all of your associated users.`,
                  actions: [
                    {
                      text: 'Close',
                      role: 'close',
                      callback: this.dialogService.closeModal2
                    }
                  ]
                })
              })
            } else {
              this.updateBodyFont(environment.defaultBodyFont).then(res => {
                this.dialogService.openModal2({
                  type: 'success',
                  title: 'Updated Body Font',
                  content: `Successfully updated the font for you and all of your associated users.`,
                  actions: [
                    {
                      text: 'Close',
                      role: 'close',
                      callback: this.dialogService.closeModal2
                    }
                  ]
                })
              })
            }
          },
          role: 'confirm'
        },
      ]
    })
  }

  resetImage(imageType: string) {
    this.dialogService.openModal2({
      type: 'confirm',
      title: 'Confirm Reset',
      content: `Are you sure you want to reset your ${imageType} to the Tandem default? This change will immediately apply for you and all your users.`,
      actions: [
        {
          text: 'No, Cancel',
          role: 'cancel',
          callback: this.dialogService.closeModal2
        },
        {
          text: 'Yes, Confirm',
          role: "confirm",
          callback: () => {
            this.changeBrandingImage({resetLogo: imageType === 'logo' ? true : undefined, resetIcon: imageType === 'icon' ? true : undefined}).then(res => window.location.reload())
          }
        }
      ]
    })
  }

  async isGoogleFont(fontName: string) {
    const url = `https://www.googleapis.com/webfonts/v1/webfonts?key=${environment.googleFontAPIKey}`;
    const response = await fetch(url);
    const data = await response.json();

    const fontNames = data.items.map((font: { family: any; }) => font.family);

    return fontNames.includes(fontName);
  }

  async getGoogleFonts() {
    const url = `https://www.googleapis.com/webfonts/v1/webfonts?key=${environment.googleFontAPIKey}`;
    const response = await fetch(url);
    const data = await response.json();

    return data;
  }

}

export function colorValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const value = control.value;

    if (!value) {
      return null; // don't validate empty values to allow optional controls
    }

    // Hex color regex
    const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

    // RGB color regex
    const rgbRegex = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/;

    if (hexRegex.test(value)) {
      return null; // valid hex color
    }

    if (rgbRegex.test(value)) {
      const matches = value.match(rgbRegex);
      if (matches) {
        const [, r, g, b] = matches.map(Number);
        if (r <= 255 && g <= 255 && b <= 255) {
          return null; // valid RGB color
        }
      }
    }

    return { 'invalidColor': { value: control.value } };
  };
}

export function urlValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    console.log(control.value);
    const urlRegex = /^https:\/\/[a-zA-Z0-9\-._~:\/?#\[\]@!$&'()*+,;=]+$/;
    const value = control.value;

    if (!value) {
      return null; // Allow empty values; use `Validators.required` for non-empty validation
    }

    return urlRegex.test(value) ? null : { invalidUrl: true };
  };
}
