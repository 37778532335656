export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyAL5VFfQ-vgL5UofCiLb-MLVapjmKKBON8",
    authDomain: "tandem-finance-prd.firebaseapp.com",
    projectId: "tandem-finance-prd",
    storageBucket: "tandem-finance-prd.appspot.com",
    messagingSenderId: "185129279683",
    appId: "1:185129279683:web:06ea1483331d896b578276",
    measurementId: "G-S379X9RRGB"
  },
  environmentName: 'Production',
  stripePublicKey: 'pk_live_51ODUUiEIxj4fplMbNDdOTdbPe71TCDAvBP1hwxpOCngNz4UCOwgWlWj7x23LW1cgWJCX5nDaflclFUVMTFVElscR00PJJ52Vpg',
  // stripePublicKey: 'pk_test_51ODUUiEIxj4fplMbKFOzhevgkbwc2zbC57n3FHbnx1L9jbWQ3t4cF56GtXiF1x9Cv44FjwOrGaXGp4IUD9nSeN2w00FasWUWsC',
  functionsPath: 'https://us-central1-tandem-finance-prd.cloudfunctions.net',
  logoPath: 'assets/svg/logo/full_blue.svg',
  whiteLogoPath: 'assets/svg/logo/full_white.svg',
  whiteIconPath: 'assets/svg/logo/icon_white.svg',
  primaryIconPath: 'assets/svg/logo/icon_blue.svg',
  defaultTheme: '#4660ea',
  defaultHeaderFont: 'DM Sans',
  defaultBodyFont: 'DM Sans',
  enableGridDisplay: false,
  defaultThemeConfigurationId: 'Ei3UegUkdmWNy8xynNh2iEQnT4z1',
  defaultVideoConfig: {
    userWelcomeRev: 'https://www.youtube.com/embed/HtPZFw1Abvw?si=xqJYiaiV1iETY_A9',
    userWelcomeLead: 'https://www.youtube.com/embed/HtPZFw1Abvw?si=xqJYiaiV1iETY_A9',
    userWelcomeIndividual: 'https://www.youtube.com/embed/HtPZFw1Abvw?si=xqJYiaiV1iETY_A9',
    coachWelcomeRev: 'https://www.youtube.com/embed/M67GzX6_wFo?si=vzIZiqHSFVQN5tDn',
    coachWelcomeLead: 'https://www.youtube.com/embed/M67GzX6_wFo?si=vzIZiqHSFVQN5tDn',
    financialPosition: 'https://www.youtube.com/embed/M5kGEj9lKa8?si=wWDxKLBUAkeODQRy',
    cashFlow: 'https://www.youtube.com/embed/ka_eNvEpuvk?si=YJS2BiDP0fJm5DRS',
    spendingPlan: 'https://www.youtube.com/embed/Z3R19NOS4YE?si=wzer92zTSvwzqhfR',
    debtPayoff: 'https://www.youtube.com/embed/Qjb-Z732rn4?si=dQjpA9DUuz2L7rxs',
    investment: 'https://www.youtube.com/embed/OhNV93lqg-0?si=s2BjK6DcDlVS_5Pz',
    debtVsInvest: 'https://www.youtube.com/embed/dQpy4EJczsM?si=cIbpExh6MZwQTUWS',
  },
  defaultFPid: '3gpYekojdwMdoEhzUiiD',
  defaultCFid: 'wl5efGTIXSZj0UK7zfZc',
  defaultSPid: 'YEgxIFJbKbtzRLfwbX29',
  defaultDomain: 'https://platform.tandemfinance.app',
  defaultCoachId: 'IcXHqfgyz0TJKoTNWHg1GRBOqd23',
  googleFontAPIKey: 'AIzaSyC78zxgzMm4TZJ938i6XOwdHczOcMTngC0'
};
