<div class="flex justify-center w-full">
  <div class="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20 max-w-4xl">
    <div class="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
      <div>
        <h2 class="font-header text-base font-semibold leading-7 text-gray-900">Profile</h2>
        <p class="mt-1 text-sm leading-6 text-gray-500">Information about your account and who you are</p>

        <dl class="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Full name</dt>
            <form class="mt-1 flex justify-between items-center gap-x-6 sm:mt-0 sm:flex-auto"  (ngSubmit)="closeNameEdit(true)"
                  [appEnterKeySubmit]="displayNameForm"
                  [onSubmit]="closeNameEdit.bind(this, true)">
              <div *ngIf="!editingName" class="text-gray-900">{{user?.displayName}}</div>
              <tandem-form-input *ngIf="editingName" [autoFocus]="true" [form]="displayNameForm" controlName="name" label="" placeholder="Full Name"></tandem-form-input>
              <button *ngIf="!editingName" type="button" class="font-semibold text-primary hover:text-primary" (click)="editingName = true">Update</button>
              <div *ngIf="editingName" class="flex gap-4">
                <tandem-button color="white" [onClick]="closeNameEdit.bind(this, false)">Cancel</tandem-button>
                <tandem-button color="primary" [onClick]="closeNameEdit.bind(this, true)">Save</tandem-button>
              </div>
            </form>
          </div>
          <div *ngIf="user?.accountType === 'coach'" class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Business Name</dt>
            <form class="mt-1 flex justify-between items-center gap-x-6 sm:mt-0 sm:flex-auto"  (ngSubmit)="closeBusinessNameEdit(true)"
                  [appEnterKeySubmit]="businessNameForm"
                  [onSubmit]="closeBusinessNameEdit.bind(this, true)">
              <div *ngIf="!editingCoachBusinessName" class="text-gray-900">{{user?.coachBusinessName}}</div>
              <tandem-form-input *ngIf="editingCoachBusinessName" [autoFocus]="true" [form]="businessNameForm" controlName="name" label="" placeholder="Business Name"></tandem-form-input>
              <button *ngIf="!editingCoachBusinessName" type="button" class="font-semibold text-primary hover:text-primary" (click)="editingCoachBusinessName = true">Update</button>
              <div *ngIf="editingCoachBusinessName" class="flex gap-4">
                <tandem-button color="white" [onClick]="closeBusinessNameEdit.bind(this, false)">Cancel</tandem-button>
                <tandem-button color="primary" [onClick]="closeBusinessNameEdit.bind(this, true)">Save</tandem-button>
              </div>
            </form>
          </div>
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Profile Picture</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="h-11 w-11 flex-shrink-0">
                <img *ngIf="user?.photoURL" class="h-11 w-11 rounded-full" [src]="user?.photoURL" alt="{{user?.displayName}}">
                <span *ngIf="!user?.photoURL" class="inline-flex h-11 w-11 items-center justify-center rounded-full bg-primary">
                <span class="font-medium leading-none text-white">{{ user?.firstName?.at(0)?.toUpperCase() }}{{user?.lastName?.at(0)?.toUpperCase()}}</span>
              </span>
              </div>
              <input #fileInput type="file" id="fileInput" (change)="uploadPhoto($event)" hidden>
              <button type="button" (click)="fileInput.click()" class="font-semibold text-primary hover:text-primary">Update</button>
            </dd>
          </div>
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email address</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-gray-900">{{user?.email}}</div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
              </svg>

            </dd>
          </div>
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Password</dt>
            <form class="mt-1 flex justify-between items-center gap-x-6 sm:mt-0 sm:flex-auto" (ngSubmit)="updatePassword()">
              <div *ngIf="!editingPassword" class="text-gray-900">••••••••</div>
              <div *ngIf="editingPassword" class="w-full">
                <tandem-form-input *ngIf="editingPassword" [form]="passwordForm" controlName="currentPassword" label="Current Password" placeholder="Current Password" [autoFocus]="true"></tandem-form-input>
                <tandem-form-input *ngIf="editingPassword" [form]="passwordForm" controlName="newPassword" label="New Password" placeholder="New Password"></tandem-form-input>
                <tandem-form-input *ngIf="editingPassword" [form]="passwordForm" controlName="confirmPassword" label="Confirm Password" placeholder="Confirm Password"></tandem-form-input>
              </div>
              <button *ngIf="!editingPassword" type="button" class="font-semibold text-primary hover:text-primary" (click)="editingPassword = true">Update</button>
              <div *ngIf="editingPassword" class="flex gap-4">
                <tandem-button color="white" [onClick]="cancelPasswordEdit.bind(this)">Cancel</tandem-button>
                <tandem-button color="primary" [onClick]="updatePassword.bind(this)" [disabled]="passwordForm.get('currentPassword')?.invalid ||passwordForm.get('newPassword')?.invalid ||passwordForm.get('confirmPassword')?.invalid">Save</tandem-button>
              </div>
            </form>
          </div>
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Phone Number</dt>
            <form class="mt-1 flex justify-between items-center gap-x-6 sm:mt-0 sm:flex-auto"  (ngSubmit)="closePhoneNumber(true)"
                  [appEnterKeySubmit]="phoneNumberForm"
                  [onSubmit]="closePhoneNumber.bind(this, true)">
              <div *ngIf="!editingPhoneNumber && user?.phoneNumber" class="text-gray-900">{{user?.phoneNumber}}</div>
              <div *ngIf="!editingPhoneNumber && !user?.phoneNumber" class="text-gray-900 italic">No phone number</div>
              <tandem-form-input *ngIf="editingPhoneNumber" [maskType]="'phone'" [autoFocus]="true" [form]="phoneNumberForm" controlName="phoneNumber" label="" placeholder="Phone Number"></tandem-form-input>
              <button *ngIf="!editingPhoneNumber" type="button" class="font-semibold text-primary hover:text-primary" (click)="editingPhoneNumber = true">Update</button>
              <div *ngIf="editingPhoneNumber" class="flex gap-4">
                <tandem-button color="white" [onClick]="closePhoneNumber.bind(this, false)">Cancel</tandem-button>
                <tandem-button color="primary" [onClick]="closePhoneNumber.bind(this, true)">Save</tandem-button>
              </div>
            </form>
          </div>
          <div *ngIf="user?.accountType === 'coach'" class="pt-6 sm:flex">
            <div class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
              <dt class="">Video Call Link</dt>
              <tandem-tooltip [text]="videoCallLinkTooltip" class="ml-2"></tandem-tooltip>
            </div>
            <form class="mt-1 flex justify-between items-center gap-x-6 sm:mt-0 sm:flex-auto"  (ngSubmit)="closeVideoCallLink(true)"
                  [appEnterKeySubmit]="videoCallLinkForm"
                  [onSubmit]="closeVideoCallLink.bind(this, true)">
              <div *ngIf="!editingVideoCallLink && user?.videoCallLink" class="text-gray-900">{{user?.videoCallLink}}</div>
              <div *ngIf="!editingVideoCallLink && !user?.videoCallLink" class="text-gray-900 italic">No Video Call Link</div>
              <tandem-form-input *ngIf="editingVideoCallLink" [autoFocus]="true" [form]="videoCallLinkForm" controlName="videoCallLink" label="" placeholder="Video Call Link"></tandem-form-input>
              <button *ngIf="!editingVideoCallLink" type="button" class="font-semibold text-primary hover:text-primary" (click)="editingVideoCallLink = true">Update</button>
              <div *ngIf="editingVideoCallLink" class="flex gap-4">
                <tandem-button color="white" [onClick]="closeVideoCallLink.bind(this, false)">Cancel</tandem-button>
                <tandem-button color="primary" [disabled]="videoCallLinkForm.invalid" [onClick]="closeVideoCallLink.bind(this, true)">Save</tandem-button>
              </div>
            </form>
          </div>
          <div class="pt-6 sm:flex" *ngIf="user?.accountType === 'user'">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Coach</dt>
            <dd *ngIf="coach" class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="flex items-center">
                <div class="h-11 w-11 flex-shrink-0">
                  <img *ngIf="coach?.photoURL" class="h-11 w-11 rounded-full" [src]="coach.photoURL" alt="{{coach.displayName}}">
                  <span *ngIf="!coach?.photoURL" class="inline-flex h-11 w-11 items-center justify-center rounded-full bg-primary">
                  <span class="font-medium leading-none text-white">{{ coach.firstName?.at(0)?.toUpperCase() }}{{coach.lastName?.at(0)?.toUpperCase()}}</span>
                </span>
                </div>
                <div class="ml-4">
                  <div class="font-medium text-gray-900">{{coach.displayName}}</div>
                  <div class="mt-1 text-gray-500">{{coach.email}}</div>
                </div>
              </div>
            </dd>
            <dd *ngIf="!coach" class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-gray-900">No coach assigned</div>
            </dd>
          </div>
        </dl>
      </div>
      <div *ngIf="user?.accountType === 'coach'">
        <h2 class="font-header text-base font-semibold leading-7 text-gray-900">Branding</h2>
        <p class="mt-1 text-sm leading-6 text-gray-500">Change your color scheme, fonts, etc lorem ipsum dolor</p>
        <dl class="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div class="pt-6 sm:flex">
            <div class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
              <dt class="">Primary Color</dt>
              <tandem-tooltip [text]="colorTooltip" class="ml-2"></tandem-tooltip>
            </div>
            <form class="mt-1 flex justify-between items-center gap-x-6 sm:mt-0 sm:flex-auto"  (ngSubmit)="closeColorEdit(true)"
                  [appEnterKeySubmit]="colorForm"
                  [onSubmit]="closeColorEdit.bind(this, true)">
              <div *ngIf="!editingColor" class="mt-1 text-gray-500 flex items-center"><div class="inline-block w-6 h-6 rounded-md mr-4 bg-primary"></div>{{user?.primaryColor || '#BF8E44'}}</div>
              <tandem-form-input *ngIf="editingColor" [form]="colorForm" controlName="color" label="" placeholder="Color" [autoFocus]="true"></tandem-form-input>
              <div class="flex gap-8">
                <button *ngIf="!editingColor" type="button" class="font-semibold" (click)="resetColor()">Reset</button>
                <button *ngIf="!editingColor" type="button" class="font-semibold text-primary hover:text-primary" (click)="editingColor = true">Customize</button>
              </div>
              <div *ngIf="editingColor" class="flex gap-4">
                <tandem-button color="white" [onClick]="closeColorEdit.bind(this, false)">Cancel</tandem-button>
                <tandem-button color="primary" [disabled]="colorForm.invalid" [onClick]="closeColorEdit.bind(this, true)">Save</tandem-button>
              </div>
            </form>
          </div>
          <div class="pt-6 sm:flex">
            <div class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
              <dt class="">Header Font</dt>
              <tandem-tooltip [text]="fontTooltip" class="ml-2"></tandem-tooltip>
            </div>
            <form class="mt-1 flex justify-between items-center gap-x-6 sm:mt-0 sm:flex-auto"  (ngSubmit)="closeFontEdit(true, 'header')"
                  [appEnterKeySubmit]="headerFontForm"
                  [onSubmit]="closeFontEdit.bind(this, true, 'header')">
              <div *ngIf="!editingHeaderFont" class="mt-1 text-gray-500 flex items-center">{{user?.headerFont || 'DM Sans'}}</div>



              <tandem-form-input *ngIf="editingHeaderFont" [form]="headerFontForm" controlName="font" label="" placeholder="Header Font" [autoFocus]="true"></tandem-form-input>
              <div class="flex gap-8">
                <button *ngIf="!editingHeaderFont" type="button" class="font-semibold" (click)="resetFont('header')">Reset</button>
                <button *ngIf="!editingHeaderFont" type="button" class="font-semibold text-primary hover:text-primary" (click)="editingHeaderFont = true">Customize</button>
              </div>
              <div *ngIf="editingHeaderFont" class="flex gap-4">
                <tandem-button color="white" [onClick]="closeFontEdit.bind(this, false, 'header')">Cancel</tandem-button>
                <tandem-button color="primary" [onClick]="closeFontEdit.bind(this, true, 'header')">Save</tandem-button>
              </div>
            </form>
          </div>
          <div class="pt-6 sm:flex">
            <div class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
              <dt class="">Body Font</dt>
              <tandem-tooltip [text]="fontTooltip" class="ml-2"></tandem-tooltip>
            </div>
            <form class="mt-1 flex justify-between items-center gap-x-6 sm:mt-0 sm:flex-auto"  (ngSubmit)="closeFontEdit(true, 'body')"
                  [appEnterKeySubmit]="bodyFontForm"
                  [onSubmit]="closeFontEdit.bind(this, true, 'body')">
              <div *ngIf="!editingBodyFont" class="mt-1 text-gray-500 flex items-center">{{user?.bodyFont || 'DM Sans'}}</div>
              <tandem-form-input *ngIf="editingBodyFont" [form]="bodyFontForm" controlName="font" label="" placeholder="Body Font" [autoFocus]="true"></tandem-form-input>
              <div class="flex gap-8">
                <button *ngIf="!editingBodyFont" type="button" class="font-semibold" (click)="resetFont('body')">Reset</button>
                <button *ngIf="!editingBodyFont" type="button" class="font-semibold text-primary hover:text-primary" (click)="editingBodyFont = true">Customize</button>
              </div>
              <div *ngIf="editingBodyFont" class="flex gap-4">
                <tandem-button color="white" [onClick]="closeFontEdit.bind(this, false, 'body')">Cancel</tandem-button>
                <tandem-button color="primary" [onClick]="closeFontEdit.bind(this, true, 'body')">Save</tandem-button>
              </div>
            </form>
          </div>
          <div class="pt-6 sm:flex">
            <div class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
              <dt class="">Logo</dt>
              <tandem-tooltip [text]="logoTooltip" class="ml-2"></tandem-tooltip>
            </div>            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class=" flex-shrink-0">
                <img *ngIf="user?.logoURL" class="w-96" [src]="user?.logoURL" alt="Logo">
                <img *ngIf="!user?.logoURL" class="w-96 h-auto" [src]="environment.logoPath" alt="Logo">
              </div>
            <input #logoInput type="file" id="logoInput" (change)="uploadLogo($event)" accept=".svg,image/svg+xml" hidden>
              <div class="flex gap-8">
                <button type="button" class="font-semibold" (click)="resetImage('logo')">Reset</button>
                <button type="button" (click)="logoInput.click()" class="font-semibold text-primary hover:text-primary">Update</button>
              </div>
            </dd>
          </div>
          <div class="pt-6 sm:flex">
            <div class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex">
              <dt class="">Icon</dt>
              <tandem-tooltip [text]="iconTooltip" class="ml-2"></tandem-tooltip>
            </div>                <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class=" flex-shrink-0">
                <img *ngIf="user?.iconURL" class="w-64 h-auto" [src]="user?.iconURL" alt="Icon">
                <img *ngIf="!user?.iconURL" class="w-64 h-auto" [src]="environment.primaryIconPath" alt="Icon">
              </div>
              <input #iconInput type="file" id="iconInput" (change)="uploadIcon($event)" accept=".svg,image/svg+xml" hidden>
              <div class="flex gap-8">
                <button type="button" class="font-semibold" (click)="resetImage('icon')">Reset</button>
                <button type="button" (click)="iconInput.click()" class="font-semibold text-primary hover:text-primary">Update</button>
              </div>
            </dd>
          </div>
        </dl>
      </div>

<!--      <div *tandemAuth="'isCoach'">-->
<!--        <h2 class="font-header text-base font-semibold leading-7 text-gray-900">Branding</h2>-->
<!--        <p class="mt-1 text-sm leading-6 text-gray-500">Change your color scheme, fonts, etc lorem ipsum dolor</p>-->
<!--        <ul role="list" class="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">-->
<!--          <li class="flex justify-between gap-x-6 py-6">-->
<!--            <div class="font-medium text-gray-900">Primary Color</div>-->
<!--            <div class="mt-1 text-gray-500 flex items-center"><div class="inline-block w-6 h-6 rounded-md mr-4 bg-primary"></div>#BF8E44</div>-->
<!--            <button type="button" class="font-semibold text-primary hover:text-primary">Customize</button>-->
<!--          </li>-->
<!--          <li class="flex justify-between gap-x-6 py-6">-->
<!--            <div class="font-medium text-gray-900">Heading Font</div>-->
<!--            <div class="mt-1 text-gray-500 flex items-center">DM Sans</div>-->
<!--            <button type="button" class="font-semibold text-primary hover:text-primary">Customize</button>-->
<!--          </li>-->
<!--          <li class="flex justify-between gap-x-6 py-6">-->
<!--            <div class="font-medium text-gray-900">Body Font</div>-->
<!--            <div class="mt-1 text-gray-500 flex items-center">DM Sans</div>-->
<!--            <button type="button" class="font-semibold text-primary hover:text-primary">Customize</button>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->

      <ng-container *ngIf="user?.accountType === 'user' && !!user?.coachId">
        <div *tandemAuth="'trackingTools'">
          <h2 class="font-header text-base font-semibold leading-7 text-gray-900">Tracking Tool Visibility</h2>
          <p class="mt-1 text-sm leading-6 text-gray-500">Decide whether or not {{coach?.displayName}} should be able to see your tracking tool data</p>

          <ul [formGroup]="visibilityForm" role="list" class="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <li class="flex justify-between gap-x-6 py-6">
              <div class="font-medium text-gray-900">{{user?.customTTMetadata?.fpNamePlural || 'Financial Positions'}}</div>
              <tandem-form-switch [form]="visibilityForm" [controlName]="'fp'" [label]="getLabel('fp')"></tandem-form-switch>
            </li>
            <li class="flex justify-between gap-x-6 py-6">
              <div class="font-medium text-gray-900">{{(user?.customTTMetadata?.cashFlowNamePlural) ?  user?.customTTMetadata?.cashFlowNamePlural : 'Cash Flows'}}</div>
              <tandem-form-switch [form]="visibilityForm" [controlName]="'cf'" [label]="getLabel('cf')"></tandem-form-switch>
            </li>
            <li class="flex justify-between gap-x-6 py-6">
              <div class="font-medium text-gray-900">{{(user?.customTTMetadata?.spendingPlanNamePlural) ? user?.customTTMetadata?.spendingPlanNamePlural : 'Spending Plans'}}</div>
              <tandem-form-switch [form]="visibilityForm" [controlName]="'sp'" [label]="getLabel('sp')"></tandem-form-switch>
            </li>
          </ul>
        </div>
      </ng-container>

      <div *ngIf="user?.accountType === 'coach'">
        <h2 class="font-header text-base font-semibold leading-7 text-gray-900">Tracking Tool Customization</h2>
        <p class="mt-1 text-sm leading-6 text-gray-500">Rename, add, and delete items to make the Tracking Tools true to your brand and money philosophy</p>

        <ul role="list" class="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <li class="flex justify-between gap-x-6 py-6">
            <div class="font-medium text-gray-900">{{user?.customTTMetadata?.fpNamePlural || 'Financial Positions'}}</div>
            <button type="button" class="font-semibold text-primary hover:text-primary" routerLink="/coaching/customize/financial-position">Customize</button>
          </li>
          <li class="flex justify-between gap-x-6 py-6">
            <div class="font-medium text-gray-900">{{(user?.customTTMetadata?.cashFlowNamePlural && user?.customTTMetadata?.spendingPlanNamePlural) ?  user?.customTTMetadata?.cashFlowNamePlural + ' and ' + user?.customTTMetadata?.spendingPlanNamePlural : 'Cash Flows and Spending Plans'}}</div>
            <button type="button" class="font-semibold text-primary hover:text-primary" routerLink="/coaching/customize/statement">Customize</button>
          </li>
        </ul>
      </div>

      <div *ngIf="user?.accountType === 'coach'">
        <h2 class="font-header text-base font-semibold leading-7 text-gray-900">Video Maintenance</h2>
        <p class="mt-1 text-sm leading-6 text-gray-500">Configure what videos your users will see at different points in their journey</p>
        <p class="mt-1 text-sm leading-6 text-gray-500">View our <a class="text-primary hover:cursor-pointer">video guidelines</a> if you are experiencing troubles</p>

        <ul role="list" class="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <li *ngFor="let vid of videoConfigs; let i = index" class="w-full flex flex-col">
            <div class="w-full flex justify-between gap-x-6 py-6">
              <div class="font-medium text-gray-900">{{vid.title}}</div>
              <div class="flex flex-row gap-4 sm:gap-6">
                <button type="button" class="font-semibold text-primary hover:text-primary" (click)="openVideo(vid.videoUrl)">View</button>
                <button type="button" class="font-semibold text-primary hover:text-primary" (click)="toggleVideoEdit(i)">Update</button>
              </div>
            </div>
            <form *ngIf="editingVideoURL === i" class="w-full flex flex-col md:flex-row justify-between md:gap-10 items-center" (ngSubmit)="updateURL(i)"
                  [appEnterKeySubmit]="videoForm"
                  [onSubmit]="updateURL.bind(this, i)">
              <tandem-form-input class="w-full max-w-3xl" [form]="videoForm" [controlName]="'url'" label="Video URL" placeholder="Video URL"></tandem-form-input>
              <div class="flex gap-4 mt-2">
                <tandem-button color="white" [onClick]="toggleVideoEdit.bind(this, i)">Cancel</tandem-button>
                <tandem-button color="primary" [onClick]="updateURL.bind(this, i)">Save</tandem-button>
              </div>
            </form>
          </li>
<!--          <li class="flex justify-between gap-x-6 py-6">-->
<!--            <div class="font-medium text-gray-900">{{user?.customTTMetadata?.fpNamePlural || 'Financial Positions'}}</div>-->
<!--            <div class="flex flex-row gap-4 sm:gap-6">-->
<!--              <button type="button" class="font-semibold text-primary hover:text-primary" (click)="openVideo('financialPosition')">View</button>-->
<!--              <button type="button" class="font-semibold text-primary hover:text-primary">Update</button>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li class="flex justify-between gap-x-6 py-6">-->
<!--            <div class="font-medium text-gray-900">{{user?.customTTMetadata?.cashFlowNamePlural || 'Cash Flows'}}</div>-->
<!--            <div class="flex flex-row gap-4 sm:gap-6">-->
<!--              <button type="button" class="font-semibold text-primary hover:text-primary" (click)="openVideo('cashFlow')">View</button>-->
<!--              <button type="button" class="font-semibold text-primary hover:text-primary">Update</button>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li class="flex justify-between gap-x-6 py-6">-->
<!--            <div class="font-medium text-gray-900">{{user?.customTTMetadata?.spendingPlanNamePlural || 'Spending Plans'}}</div>-->
<!--            <div class="flex flex-row gap-4 sm:gap-6">-->
<!--              <button type="button" class="font-semibold text-primary hover:text-primary" (click)="openVideo('spendingPlan')">View</button>-->
<!--              <button type="button" class="font-semibold text-primary hover:text-primary">Update</button>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li class="flex justify-between gap-x-6 py-6">-->
<!--            <div class="font-medium text-gray-900">Debt Payoff Calculator</div>-->
<!--            <div class="flex flex-row gap-4 sm:gap-6">-->
<!--              <button type="button" class="font-semibold text-primary hover:text-primary" (click)="openVideo('debtPayoff')">View</button>-->
<!--              <button type="button" class="font-semibold text-primary hover:text-primary">Update</button>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li class="flex justify-between gap-x-6 py-6">-->
<!--            <div class="font-medium text-gray-900">Investment Calculator</div>-->
<!--            <div class="flex flex-row gap-4 sm:gap-6">-->
<!--              <button type="button" class="font-semibold text-primary hover:text-primary" (click)="openVideo('investment')">View</button>-->
<!--              <button type="button" class="font-semibold text-primary hover:text-primary">Update</button>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li class="flex justify-between gap-x-6 py-6">-->
<!--            <div class="font-medium text-gray-900">Debt Vs. Invest Calculator</div>-->
<!--            <div class="flex flex-row gap-4 sm:gap-6">-->
<!--              <button type="button" class="font-semibold text-primary hover:text-primary" (click)="openVideo('debtVsInvest')">View</button>-->
<!--              <button type="button" class="font-semibold text-primary hover:text-primary">Update</button>-->
<!--            </div>-->
<!--          </li>-->
        </ul>
      </div>

      <div *ngIf="user?.stripeAccountId || user?.stripeCustomerId" class="bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:p-6">
          <h3 class="text-base font-semibold leading-6 text-gray-900">View Billing Information</h3>
          <div class="mt-2 max-w-xl text-sm text-gray-500">
            <p>Click the button below to be taken to a Stripe portal that breaks down your all your billing information.</p>
          </div>
          <div class="mt-5 flex flex-col items-start">
            <tandem-button color="primary" class="w-full sm:w-fit" [onClick]="openStripeBillingInfo.bind(this)">Go to Stripe</tandem-button>
          </div>
        </div>
      </div>

      <div class="bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:p-6">
          <h3 class="text-base font-semibold leading-6 text-gray-900">Contact Support</h3>
          <div class="mt-2 max-w-xl text-sm text-gray-500">
            <p>Have any questions? Are you or your users experiencing some sort of issue? Let us know what you need.</p>
          </div>
          <div *ngIf="editingContact" class="flex flex-col w-full mt-8">
            <tandem-form-input class="w-full" [form]="feedbackForm" [controlName]="'subject'" label="Subject" placeholder="Subject"></tandem-form-input>
            <tandem-form-textarea class="w-full-3xl" [form]="feedbackForm" [controlName]="'feedback'" label="Message" placeholder="Message"></tandem-form-textarea>
            <div class="flex justify-end gap-4">
              <tandem-button color="white" [onClick]="closeFeedback.bind(this, false)">Cancel</tandem-button>
              <tandem-button color="primary" [onClick]="closeFeedback.bind(this, true)">Send Message</tandem-button>
            </div>
          </div>
          <div *ngIf="!editingContact" class="mt-5 flex flex-col items-start">
            <tandem-button color="primary" class="w-full sm:w-fit" [onClick]="promptEditContact.bind(this)">Contact</tandem-button>
          </div>
        </div>
      </div>
<!--      <div *ngIf="user || coach || userSubscription">-->
<!--        <tandem-user-sub-management [user]="user" [coach]="coach" [userSubscription]="userSubscription"></tandem-user-sub-management>-->
<!--      </div>-->

<!--      <div class="bg-white shadow sm:rounded-lg" *ngIf="user?.accountType === 'coach' && user?.coachType === 'coachRevShare'">-->
<!--        <div class="px-4 py-5 sm:p-6">-->
<!--          <h3 class="text-base font-semibold leading-6 text-gray-900">Active Subscription - Coach Rev Share</h3>-->
<!--          <div class="mt-2 max-w-xl text-sm text-gray-500">-->
<!--            <p>Active subscription since {{user?.dateCreated?.toDate() | date }}</p>-->
<!--            <p>Current user count: 43</p>-->
<!--            <p>Estimated monthly revenue: $10.99 x 43 = {{472.57 | currency}}</p>-->
<!--          </div>-->
<!--          <div class="mt-5 flex flex-col items-start">-->
<!--            <tandem-button color="red" class="w-full sm:w-fit">Cancel Subscription</tandem-button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="bg-white shadow sm:rounded-lg" *ngIf="user?.accountType === 'coach' && user?.coachType === 'coachLeadGen'">-->
<!--        <div class="px-4 py-5 sm:p-6">-->
<!--          <h3 class="text-base font-semibold leading-6 text-gray-900">Active Subscription - Coach Lead Gen</h3>-->
<!--          <div class="mt-2 max-w-xl text-sm text-gray-500">-->
<!--            <p>Active subscription since {{user?.dateCreated?.toDate() | date }}</p>-->
<!--            <p>Your price: $10.99 per user per month</p>-->
<!--            <p>Current maximum user count: 43</p>-->
<!--            <p>Estimated total due on Mar 7th, 2024: $10.99 x 43 users = {{472.57 | currency}}</p>-->
<!--          </div>-->
<!--          <div class="mt-5 flex flex-col items-start">-->
<!--            <tandem-button color="red" class="w-full sm:w-fit">Cancel Subscription</tandem-button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="bg-white shadow sm:rounded-lg" *ngIf="user?.accountType === 'user'">-->
<!--        <div class="px-4 py-5 sm:p-6">-->
<!--          <ng-container *ngIf="userSubscription && userSubscription.status === 'SUCCESS'">-->
<!--            <h3 class="text-base font-semibold leading-6 text-gray-900">Active Subscription</h3>-->
<!--            <div class="mt-2 max-w-xl text-sm text-gray-500">-->
<!--              <p>Active subscription since {{user?.dateCreated?.toDate() | date }}</p>-->
<!--              <p>Your price: {{ userSubscription.amount / 100 | currency }} per month</p>-->
<!--              <p>Next billing date: {{userSubscription.nextBillingDate.toDate() | date}}</p>-->
<!--            </div>-->
<!--            <div class="mt-5 flex flex-col items-start">-->
<!--              <tandem-button color="red" class="w-full sm:w-fit">Cancel Subscription</tandem-button>-->
<!--            </div>-->
<!--          </ng-container>-->
<!--          <ng-container *ngIf="coach?.coachType === 'coachLeadGen' && user?.status === 'Active'">-->
<!--            <h3 class="text-base font-semibold leading-6 text-gray-900">Active Subscription</h3>-->
<!--            <div class="mt-2 max-w-xl text-sm text-gray-500">-->
<!--              <p>Active subscription</p>-->
<!--              <p>Your subscription status is managed by your coach.</p>-->
<!--            </div>-->
<!--            <div class="mt-5 flex flex-col items-start">-->
<!--              <tandem-button color="red" class="w-full sm:w-fit" [onClick]="cancelUserSubscription.bind(this)">Cancel Subscription</tandem-button>-->
<!--            </div>-->
<!--          </ng-container>-->
<!--          <ng-container *ngIf="coach?.coachType === 'coachLeadGen' && user?.status === 'Pending'">-->
<!--            <h3 class="text-base font-semibold leading-6 text-gray-900">Pending Subscription</h3>-->
<!--            <div class="mt-2 max-w-xl text-sm text-gray-500">-->
<!--              <p>Pending subscription</p>-->
<!--              <p>Your coach must grant you access to the Tandem platform</p>-->
<!--            </div>-->
<!--          </ng-container>-->
<!--          <ng-container *ngIf="coach?.coachType === 'coachLeadGen' && (user?.status === 'Paused' || user?.status === 'Denied')">-->
<!--            <h3 class="text-base font-semibold leading-6 text-gray-900">Inactive Subscription</h3>-->
<!--            <div class="mt-2 max-w-xl text-sm text-gray-500">-->
<!--              <p>Inactive subscription</p>-->
<!--              <p>Your coach has either paused or denied your access. If you believe this is a mistake, please reach out to your coach or Tandem Support.</p>-->
<!--            </div>-->
<!--          </ng-container>-->

<!--        </div>-->
<!--      </div>-->

    </div>
  </div>
</div>
